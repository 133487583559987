<template>
  <div class="row mx-2 my-0">
    <div
      class="
        col-12 col-md-10 col-lg-8
        offset-md-1 offset-lg-2
        card
        theme-card
        border-0
        py-5
        px-4
      "
    >
      <h1 class="mb-5">{{ isEditMode ? "Edit" : "Create" }} Treatment</h1>
      <alert v-if="loadingTreatment" class="light-shadow mx-4 mb-4" />
      <form
        v-else
        @submit.prevent="createTreatmentHelper"
        validate
        class="d-flex flex-column"
      >
        <div class="form-group d-flex flex-md-row flex-column">
          <label class="col-md-3 col-lg-2 px-0"> Service </label>
          <multiselect
            class="app-select"
            v-model="selectedService"
            :options="dropdownServices"
            :loading="activeServicesLoading"
            placeholder="Search for Service"
            value="value"
            label="label"
            track-by="value"
            :required="true"
          >
          </multiselect>
        </div>
        <input-control
          type="horizontal"
          v-model.trim="treatment.name"
          labelClass="col-md-3 col-lg-2"
          required
          maxLength="255"
        >
          Treatment Name
        </input-control>
        <input-control
          type="horizontal"
          v-model.trim="treatment.description"
          control="textarea"
          rows="6"
          maxLength="500"
          labelClass="col-md-3 col-lg-2"
        >
          Description
        </input-control>
        <div class="form-group d-flex flex-md-row flex-column">
          <label class="col-md-3 col-lg-2 px-0"> Treatment Length </label>
          <select required v-model="treatment.duration" class="form-control">
            <option
              v-for="option in treatmentLengths"
              :key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
        </div>
        <div class="form-group d-flex flex-md-row flex-column">
          <label class="col-md-3 col-lg-2 px-0"> Scheduled Length </label>
          <div>
            <select
              required
              v-model="treatment.scheduled_duration"
              class="form-control"
            >
              <option
                v-for="option in treatmentLengths"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
            <small class="text-muted mt-2">
              The duration for which the staff member's schedule is reserved.
              Set this longer than the treatment duration to provide a break
              between treatments. Set it shorter than the treatment duration to
              enable overlapping appointments.
            </small>
          </div>
        </div>
        <input-control
          control="number"
          type="horizontal"
          v-model="treatment.price"
          step="0.1"
          min="0"
          labelClass="col-md-3 col-lg-2"
          required
        >
          Price
        </input-control>
        <div class="form-group row">
          <label
            for="available-for-booking-id"
            class="col-form-label col-12 col-md-3 col-lg-2"
          >
            Available for Booking?
          </label>
          <div class="col">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input pointer-cursor"
                id="available-for-booking-id"
                v-model="treatment.available_for_booking"
              />
              <label
                class="custom-control-label pointer-cursor"
                for="available-for-booking-id"
              >
                {{ treatment.available_for_booking ? "Yes" : "No" }}
              </label>
            </div>
          </div>
        </div>
        <save
          classes="mt-3 save-button btn btn-theme align-self-end"
          :saving="createTreatmentLoading || updateTreatmentLoading"
        >
          {{ isEditMode ? "Update" : "Save" }}
        </save>
      </form>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      selectedService: null,
      treatment: {
        service_id: null,
        name: "",
        description: "",
        duration: null,
        scheduled_duration: null,
        price: null,
        available_for_booking: true,
      },
      loadingTreatment: false,
    };
  },
  async mounted() {
    this.generateTreatmentLengths();
    this.getActiveServices();
    if (this.isEditMode) {
      this.loadingTreatment = true;
      const treatment = await this.getTreatment(this.treatmentId);
      if (!treatment) {
        this.$router.push(`/admin/treatments`);
        return;
      }
      const service = this.activeServices.find(
        (service) => service.id === treatment.service_id
      );
      if (service) {
        this.selectedService = {
          label: service.name,
          value: service.id,
        };
      }
      this.treatment = {
        service_id: treatment.service_id,
        name: treatment.name,
        description: treatment.description,
        duration: treatment.duration,
        scheduled_duration: treatment.scheduled_duration ?? treatment.duration,
        price: treatment.price,
        available_for_booking: treatment.available_for_booking,
      };
      this.loadingTreatment = false;
    }
  },
  computed: {
    ...mapState({
      activeServices: (state) => state.services.activeServices.data,
      activeServicesLoading: (state) => state.services.activeServices.loading,
      treatmentLengths: (state) => state.treatments.treatmentLengths,
      createTreatmentLoading: (state) =>
        state.treatments.createTreatmentLoading,
      updateTreatmentLoading: (state) =>
        state.treatments.updateTreatmentLoading,
    }),
    dropdownServices() {
      return this.activeServices.map((service) => ({
        label: service.name,
        value: service.id,
      }));
    },
    treatmentId() {
      if (this.$route.params.id) {
        return this.$route.params.id;
      }
      return null;
    },
    isEditMode() {
      return !!this.treatmentId;
    },
  },
  methods: {
    ...mapActions({
      getTreatment: "treatments/getTreatment",
      createTreatment: "treatments/createTreatment",
      updateTreatment: "treatments/updateTreatment",
      generateTreatmentLengths: "treatments/generateTreatmentLengths",
      getActiveServices: "services/getActiveServices",
    }),
    createTreatmentHelper: async function () {
      this.treatment = {
        ...this.treatment,
        service_id: this.selectedService ? this.selectedService.value : null,
      };
      let result;
      if (this.isEditMode) {
        result = await this.updateTreatment({
          treatmentId: this.treatmentId,
          ...this.treatment,
        });
      } else {
        result = await this.createTreatment(this.treatment);
      }

      if (result) {
        Swal.fire({
          title: "Success",
          text: `Treatment ${
            this.isEditMode ? "updated" : "created"
          } successfully`,
          icon: "success",
        });
        this.$router.push(`/admin/treatments`);
      }
    },
  },
};
</script>
<style lang="scss"></style>
